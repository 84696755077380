.slider-container {
    display: flex;
    padding: 0 8px;
    justify-items: center;
    align-items: center;
}
.slider-container input {
    flex: 1 1 90%;
}
.slider-container .slider__value {
    font-size: 18px;
    font-weight: bold;
    width: 120px;
    text-align: center;
}
.slider-container .slider__add, .slider-container .slider__contract {
    border: none;
    background: transparent;
    font-weight: bold;
    padding: 12px;
}

/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 4px; /* Specified height */
    background: #ccc; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 32px; /* Set a specific slider handle width */
    height: 32px; /* Slider handle height */
    background: #408ddc; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 999px;
}

.slider::-moz-range-thumb {
    width: 26px; /* Set a specific slider handle width */
    height: 26px; /* Slider handle height */
    background: #406d9c; /* Green background */
    cursor: pointer; /* Cursor on hover */
}
