.app-container {

}

.mode-selector {
  display: flex;
  box-shadow: 0 5px 13px rgba(0, 0, 0, 0.1);
  position: sticky;
  z-index: 1000;
  top: 0;
}

.mode-selector button {
  flex: 1;
  border-radius: 0;
  /*height: 36px;*/
  /*background: linear-gradient(*/
  /*        0deg*/
  /*        , rgb(231, 231, 231), rgba(157, 150, 142, 0.24));*/
  /*border: none;*/
  /*font-size: 20px;*/
}
.mode-selector .selector__btn--active {
  color: white;
  background: linear-gradient(
          0deg
          , rgb(82, 138, 230), #375390);
}

.view {
  padding: 0 8px;
}

.action-header {
  display: flex;
  justify-items: center;
  align-items: center;
}
.action-header__title {
  flex: 1;
}

.margin-top-small {
  margin-top: 16px;
}

.ingridient-selector .form-group {
  display: flex;
}

@media (min-width: 480px) {
  .app-container {
    max-width: 480px;
    margin: 0 auto;
  }
}
